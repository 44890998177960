<template>
  <div class="service_view">
    <div class="serviceTitle">
      <b>Services</b>
      <div class="serviceSubTitle">
        Build with <i class="fas fa-heart" style="color: #ff5e81"></i> by
        <b>Siratee K.</b>
      </div>
    </div>
    <div class="serviceList">
      <div v-for="service in serviceList" :key="service.name">
        <router-link :to="'/services/' + service.url">
          <ServiceItemBox
            :title="service.name"
            :iconClass="service.iconClass"
            :hoverBackgroundColor="service.hoverBackgroundColor"
            :topLeftMessage="service.topLeftMessage"
          >
          </ServiceItemBox>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
const ServiceItemBox = require("../../components/ServiceItemBox.vue").default;
export default {
  data: () => {
    return {
      serviceList: [
        {
          name: "URL Shortener",
          iconClass: "fas fa-link",
          url: "",
          topLeftMessage: "Still in development"
        },
        {
          name: "Something. . .",
          iconClass: "fas fa-box",
          url: "",
          hoverBackgroundColor: "#fcba03",
          topLeftMessage: "Soon"
        }
        // {
        //   name: "URL Shortener",
        //   iconClass: "fas fa-link",
        //   url: "shortener",
        //   hoverBackgroundColor: "#4287f5"
        // }
      ]
    };
  },
  methods: {
    changeRoute: route => {
      this.$route.push(route);
    }
  },
  components: {
    ServiceItemBox: ServiceItemBox
  }
};
</script>

<style>
.serviceTitle {
  font-size: 50px;
  margin: 40px;
  margin-left: 60px;
  text-align: left;
}

.serviceSubTitle {
  font-size: 20px;
}

.serviceList {
  margin-left: 60px;
  margin-right: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  flex-wrap: wrap;
  grid-gap: 10px;
}

* {
  box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
  .serviceTitle {
    margin: 30px;
    margin-top: 100px;
    margin-left: 40px;
  }

  .serviceList {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
