import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "./assets/css/CustomColor.scss";
import firebase from "firebase";
import liff from "@line/liff";

Vue.config.productionTip = false;

liff.init({
  liffId: "1655374134-Qo8JB3mE"
});

Vue.use(VueMaterial);

const firebaseConfig = {
  apiKey: "AIzaSyAFGfgmGK_Xe_TCjPNe2DVF4VYhzzNHxIs",
  authDomain: "sirateek-me.firebaseapp.com",
  databaseURL: "https://sirateek-me.firebaseio.com",
  projectId: "sirateek-me",
  storageBucket: "sirateek-me.appspot.com",
  messagingSenderId: "537863202999",
  appId: "1:537863202999:web:0770acf4e556519de20532",
  measurementId: "G-JS4EJLVTDT"
};
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
