<template>
  <div class="ton">
    <svg id="stroke" xmlns="http://www.w3.org/2000/svg" width="0" height="0">
      <defs>
        <path
          id="line"
          d="M2 2c49.7 2.6 100 3.1 150 1.7-46.5 2-93 4.4-139.2 7.3 45.2-1.5 90.6-1.8 135.8-.6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          vector-effect="non-scaling-stroke"
        />
      </defs>
    </svg>

    <img src="../../assets/images/tonToken.png" class="tonImage" />

    <div>
      <div class="tonStroke">
        TON Token
        <svg class="tonStroke-line" viewBox="0 0 154 13">
          <use href="#line"></use>
        </svg>
        <svg class="tonStroke-line" viewBox="0 0 154 13">
          <use href="#line"></use>
        </svg>
      </div>
    </div>
    <div class="tonInfo">
      <md-card class="infoCard">
        <md-card-header>
          <div class="md-title">
            What is <b>TON Token</b> <i class="fas fa-question"></i>
          </div>
        </md-card-header>

        <md-card-content class="tonDescription">
          <b>TON Token</b> is the ERC20 Token which is based on
          <i class="fab fa-ethereum" /> Ethereum BlockChain

          <p>
            (Currently, I'm running it in the <b>Ropsten Test Net</b> but I also
            plained to serve it in the main net too.)
          </p>
        </md-card-content>
      </md-card>
      <md-card class="infoCard">
        <md-card-header>
          <div class="md-title">
            And... What is it for <i class="fas fa-question"></i>
          </div>
        </md-card-header>

        <md-card-content class="tonDescription">
          Mainly, It is designed to be the currency for being used to pay for
          the
          <router-link :to="'/services'">services</router-link> fees in my
          website. <br /><br />Also, It can be transfered to anyone in the
          ethereum network. Just like the other token.
        </md-card-content>
      </md-card>
    </div>

    <md-menu>
      <md-button md-menu-trigger>
        <i class="fab fa-ethereum" />
        {{ tonStatus.networks[tonStatus.currentViewNetwork].displayName }}
      </md-button>

      <md-menu-content>
        <md-menu-item
          v-for="data of tonStatus.networks.entries()"
          :key="data[0]"
          @click="changeCurrentNetworkView(data[0])"
        >
          <i class="fab fa-ethereum" />
          <span>{{ data[1].displayName }}</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>

    <div class="tonStatusBoard">
      <md-card class="infoCard">
        <md-card-header>
          <div class="md-title">
            <b>TON Status board</b>
            <a
              :href="
                tonStatus.networks[tonStatus.currentViewNetwork].etherScanUrl
              "
              target="_blank"
              v-if="
                tonStatus.networks[tonStatus.currentViewNetwork].etherScanUrl
              "
            >
              <md-button class="md-icon-button md-primary">
                <i class="fas fa-info-circle fa-2x"></i>
                <md-tooltip md-direction="right"
                  >View this token on Etherscan</md-tooltip
                >
              </md-button>
            </a>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="tonStatusTable">
            <TonStatusRow
              v-for="data of Object.entries(tonStatus.dataToDisplay)"
              :rowData="data[1]"
              :key="data[0]"
              @click.native="
                data[1].value ? copyToClipboard(data[1].value) : null
              "
            ></TonStatusRow>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <md-snackbar
      :md-position="snackBar.position"
      :md-duration="snackBar.isInfinity ? Infinity : snackBar.duration"
      :md-active.sync="snackBar.active"
      md-persistent
    >
      <span>{{ snackBar.message }}</span>
      <md-progress-spinner
        :md-diameter="30"
        :md-stroke="3"
        md-mode="indeterminate"
        v-if="snackBar.isLoading"
      ></md-progress-spinner>
    </md-snackbar>
  </div>
</template>

<script>
import Web3 from "web3";
const TonStatusRow = require("../../components/TonStatusRow.vue").default;
const tonAbi = require("./tokenAbi");

export default {
  components: {
    TonStatusRow: TonStatusRow
  },
  data: function() {
    return {
      tonStatus: {
        isMainNet: false,
        currentViewNetwork: 1,
        networks: [
          {
            networkName: "mainnet",
            displayName: "Ethereum Main Net",
            contractAddress: null,
            fetchFromBlockChain: false,
            infuraUrl:
              "https://mainnet.infura.io/v3/8800203d3e2e40c9b0de9dd29bd2b103",
            statusToDisplay: {
              noInfo: {
                displayProperty: "No Info",
                value: "- No Info -",
                valueClass: "grey italic"
              }
            }
          },
          {
            networkName: "ropsten",
            displayName: "Ropsten Test Net",
            contractAddress: "0xA5D545562b2aaC4Fc0b66301F00C5bb27E2bF0EC",
            fetchFromBlockChain: true,
            infuraUrl:
              "https://ropsten.infura.io/v3/8800203d3e2e40c9b0de9dd29bd2b103",
            etherScanUrl:
              "https://ropsten.etherscan.io/token/0xA5D545562b2aaC4Fc0b66301F00C5bb27E2bF0EC",
            statusToDisplay: {
              tokenName: {
                displayProperty: "Token Name"
              },
              contractAddress: {
                displayProperty: "Smart Contract Address"
              },
              blockChain: {
                displayProperty: "Token Name",
                value: "Ethereum BlockChain (Ropsten Test Net)"
              },
              totalSupply: {
                displayProperty: "Total Supply (TON)"
              },
              tokenSymbol: {
                displayProperty: "Token Symbol"
              },
              tokenDecimals: {
                displayProperty: "Token Decimals"
              },
              allowTransferStatus: {
                displayProperty: "Allow Transfer Status"
              },
              tonVersion: {
                displayProperty: "TON Version"
              }
            }
          }
        ],
        dataToDisplay: {}
      },
      snackBar: {
        active: false,
        duration: 1000,
        position: "center",
        message: "Test",
        isInfinity: false,
        isLoading: false
      }
    };
  },
  methods: {
    showSnackBar: function(message, duration, isLoading, position) {
      this.snackBar.active = true;
      this.snackBar.message = message;
      this.snackBar.duration = duration || 2000;
      this.snackBar.position = position || "center";
      this.snackBar.isLoading = isLoading || false;
    },
    copyToClipboard: function(value) {
      if (!value) {
        return;
      }

      const element = document.createElement("input");
      element.value = value;
      document.body.appendChild(element);
      element.select();
      document.execCommand("copy");
      document.body.removeChild(element);
      this.showSnackBar("Copied `" + value + "` to your clipboard", 4000);
    },
    changeCurrentNetworkView: function(toNetwork) {
      this.tonStatus.currentViewNetwork = toNetwork;
      this.getContractDetails();
    },
    getContractDetails: async function() {
      this.showSnackBar(
        "Fetching the token data from blockchain. . .",
        Infinity,
        true
      );
      this.$set(this.tonStatus, "dataToDisplay", {});
      const currentViewNetwork = this.tonStatus.currentViewNetwork;
      const currentNetwork = this.tonStatus.networks[currentViewNetwork];
      // Add currentNetwork Property
      for (const [key, value] of Object.entries(
        currentNetwork.statusToDisplay
      )) {
        this.tonStatus.dataToDisplay[key] = value;
      }
      if (!currentNetwork.fetchFromBlockChain) {
        this.snackBar.active = false;
        this.showSnackBar(
          "The web admin has disabled fetching data from blockchain for this network.",
          4000
        );
        return;
      }
      const web3 = new Web3(
        this.tonStatus.networks[this.tonStatus.currentViewNetwork].infuraUrl
      );

      let contract;
      let contractCallArray;

      try {
        contract = new web3.eth.Contract(
          tonAbi.default,
          currentNetwork.contractAddress
        );

        contractCallArray = [
          {
            property: "tokenName",
            exceptNetwork: [],
            contractFunction: contract.methods.name()
          },
          {
            property: "contractAddress",
            exceptNetwork: [],
            value: currentNetwork.contractAddress
          },
          {
            property: "tonVersion",
            exceptNetwork: [],
            contractFunction: contract.methods.getTonVersion()
          },
          {
            property: "totalSupply",
            exceptNetwork: [],
            contractFunction: contract.methods.totalSupply()
          },
          {
            property: "tokenSymbol",
            exceptNetwork: [],
            contractFunction: contract.methods.symbol()
          },
          {
            property: "tokenDecimals",
            exceptNetwork: [],
            contractFunction: contract.methods.decimals()
          },
          {
            property: "allowTransferStatus",
            exceptNetwork: [],
            contractFunction: contract.methods.getAllowTransferStatus()
          },
          {
            property: "tonVersion",
            exceptNetwork: [],
            contractFunction: contract.methods.getTonVersion()
          }
        ];
      } catch (e) {
        console.log(e);
        this.snackBar.active = false;
        this.showSnackBar(
          "An error occurred while getting data from blockchain",
          Infinity
        );
        return;
      }

      for (let index = 0; index < contractCallArray.length; index++) {
        const contractCallDetail = contractCallArray[index];
        if (contractCallDetail.exceptNetwork.includes(currentViewNetwork)) {
          contractCallDetail.value =
            "(Function calling is disabled for this network)";
          contractCallDetail.valueClass = "grey italic";
          continue;
        }
        if (typeof contractCallDetail.value !== "undefined") {
          continue;
        }

        try {
          const blockchainReply = await contractCallDetail.contractFunction.call();
          contractCallArray[index].value = blockchainReply;
        } catch (e) {
          console.log(e);
          contractCallArray[index].value = "* Error occurred *";
          contractCallArray[index].error = e;
          contractCallDetail.valueClass = "red italic";
        }
      }

      for (let index = 0; index < contractCallArray.length; index++) {
        const contractCallDetail = contractCallArray[index];
        this.$set(
          this.tonStatus.dataToDisplay[contractCallDetail.property],
          "value",
          contractCallDetail.value
        );
        if (typeof contractCallDetail.valueClass !== "undefined") {
          this.$set(
            this.tonStatus.dataToDisplay[contractCallDetail.property],
            "valueClass",
            contractCallDetail.valueClass
          );
        }
      }
      this.snackBar.active = false;
    }
  },
  mounted: function() {
    this.getContractDetails();
  }
};
</script>

<style lang="scss">
.ton {
  margin-left: 30px;
  margin-bottom: 30px;
}
.tonImage {
  width: 250px;
  height: 250px;
}

.tonStatusBoard {
  margin-left: 100px;
  margin-right: 120px;
  padding-bottom: 100px;
}

.tonStatusTable {
  .tableRow {
    display: inline-flex;
    font-size: 20px;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 10px;

    .propertyText {
      flex: 2;
      text-align: center;
      font-weight: bold;
      vertical-align: baseline;
      padding-top: 10px;
    }
    .valueBox {
      background-color: #272727;
      color: #ffffff;
      padding: 10px;
      border-radius: 10px;
      flex: 5;
      position: relative;
      transition: 0.2s;
      flex-wrap: wrap;
      min-width: 300px;

      &:hover {
        background-color: #494949;
      }
    }
  }
}

.tonInfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, 49%);
  flex-wrap: wrap;
  grid-gap: 10px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
  z-index: 1;

  .tonDescription {
    font-size: 20px;
  }
}

.infoCard {
  width: auto;
  min-width: 250px;
}

.tonStroke {
  display: inline-block;
  color: black;
  min-width: 154px;
  text-decoration: none;
  font-weight: bold;
  font-size: 40px;
  padding: 20px;
  position: relative;
  text-align: center;

  &:hover {
    .tonStroke-line:nth-child(2) {
      stroke-dashoffset: 0;
    }
  }
}

.tonStroke-line {
  display: block;
  width: calc(100% - 40px);
  height: 20px;
  stroke: #263238;
  position: absolute;
  stroke-width: 4;

  &:nth-child(2) {
    stroke-dasharray: 650px;
    stroke-dashoffset: 650px;
    stroke: darken(#f8c622, 10%);
    stroke-width: 5;
    transition: stroke-dashoffset 700ms ease-out;
  }
}

@media only screen and (max-width: 600px) {
  .ton {
    margin-left: 0;
  }
  .infoCard {
    min-width: 300px;
  }

  .tonInfo {
    grid-template-columns: repeat(auto-fit, 100%);
    margin-left: 15%;
  }

  .tonStatusBoard {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
