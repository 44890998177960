<template>
  <div class="pagenotfound">
    <div class="img_not_found">
      <img src="../../assets/images/crane.svg" class="ImgNotFound" />
    </div>
    <div class="NotFoundText">
      <div class="TitleText">
        Opps!
        <div class="TitleTextColor">404 Page Not Found</div>
      </div>
      <div class="DescriptionText">Hmmm.... `{{ $route.path }}`</div>
      <div class="DescriptionText">
        No matter how hard I try. I still not found it
        <i class="far fa-frown"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>

<style>
.pagenotfound {
  margin-top: 20px;
}

.ImgNotFound {
  width: 400px;
  height: auto;
}

.TitleText {
  margin-top: 20px;
  font-size: 50px;
  text-align: center;
}

.TitleTextColor {
  color: #ff6188;
  font-weight: bold;
  display: inline-block;
}

.DescriptionText {
  font-size: 30px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .pagenotfound {
    margin-top: 100px;
  }

  .TitleText {
    font-size: 30px;
  }

  .ImgNotFound {
    width: 300px;
    height: auto;
  }
  .DescriptionText {
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;

    word-wrap: break-word;
  }
}
</style>
