<template>
  <div id="app">
    <div class="sidenav" id="sidenav">
      <div class="menuLogo" id="menuLogo">
        <i class="fab fa-stripe-s fa-2x sicon"></i>
        <div class="divider" />
        <md-button
          class="md-icon-button md-primary menuSelectionButton"
          onclick="dropDown()"
        >
          <md-icon>menu</md-icon>
        </md-button>
      </div>
      <div class="menuItem" id="menuItem">
        <div v-for="item in menuItemList" :key="item.name">
          <router-link :to="item.router_link">
            <div
              class="menuItembox"
              :class="
                $route.path === item.router_link ? 'active' : 'not-active'
              "
            >
              <div class="menuIcon">
                <i class="fab fa-2x mIcon" :class="item.icon"></i>
              </div>
              <div class="description">{{ item.name }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItemList: [
        {
          name: "Home",
          icon: "fas fa-house-user",
          router_link: "/"
        },
        {
          name: "Services",
          icon: "fas fa-server",
          router_link: "/services"
        },
        {
          name: "Account",
          icon: "fas fa-user fa-2x mIcon",
          router_link: "/auth/signin"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.menuSelectionButton {
  display: none;
}
@media only screen and (max-width: 600px) {
  .menuSelectionButton {
    display: inline-block;
    margin-right: 20px;
    background-color: transparent;
    border-style: none;
    color: #b5b5b5;
  }
}
</style>

<style>
body,
html {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  line-height: normal;
}

#app a {
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  height: 100%;
  width: 90px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
  transition: 0.4s;
  display: block;
}

.sidenav a {
  text-decoration: none;
}

.description {
  flex: 1;
  display: none;
  font-size: 20px;
  transition: 0.4s;
  font-weight: bold;
}

.menuIcon {
  flex: 1;
  text-align: center;
  margin: 0;
  transition: 0.2s;
}

.menuItembox {
  border-style: solid;
  display: flex;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  transition: 0.2s;
  text-align: center;
  color: #b5b5b5;
  text-decoration: none;
}

.active {
  color: #03fc94;
  border-color: #03fc94;
}

.menuItembox:hover {
  color: #03fc94;
  border-color: #03fc94;
}

.menuLogo {
  display: block;
}

.menuItem {
  display: block;
}

.menu .divider {
  display: none;
}

.sicon {
  color: #fff370;
}

.main {
  margin-left: 80px;
  margin-right: -10px;
  font-size: 28px;
  transition: 0.4s;
}

.dividerVertical {
  flex: 10;
  display: block;
}

@media only screen and (min-width: 600px) {
  .sidenav:hover {
    width: 180px;
  }

  .sidenav:hover .description {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .sidenav {
    height: 70px;
    width: 100%;
    display: inline;
  }

  .menuLogo {
    display: flex;
    max-width: auto;
    flex: 1;
    margin-left: 20px;
  }

  .menuSelectionButton {
    display: inline-block;
    margin-right: 20px;
    background-color: transparent;
    border-style: none;
    color: #b5b5b5;
  }

  .divider {
    flex: 2;
    height: auto;
    display: inline-block;
  }

  .description {
    display: block;
  }

  .showMenuBox {
    height: auto;
    z-index: 11;
    transition: 0.4s;
  }

  .showMenuItem {
    display: block;
    transition: 0.4s;
  }

  .menuItem {
    display: none;
  }

  .main {
    margin-left: -8px;
    font-size: 28px;
    margin-top: 70px;
    margin-right: -10px;
  }
}
</style>
