<template>
  <div class="serviceItemBox" :style="serviceItemBoxHover">
    <div v-if="topLeftMessage" class="topLeftMessage">
      {{ topLeftMessage }}
    </div>
    <div class="serviceItemtext">
      <i :class="iconClass"></i>
      <div>{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceItemBox",
  props: {
    title: {
      type: String,
      required: true
    },
    iconClass: {
      type: String
    },
    hoverBackgroundColor: {
      type: String,
      default: "#1dec51"
    },
    servicePrice: {
      type: String,
      default: "-.--"
    },
    topLeftMessage: {
      type: String
    }
  },
  data() {
    return {
      hovering: false
    };
  },
  computed: {
    serviceItemBoxHover() {
      return "--onHover-color: " + this.hoverBackgroundColor;
    }
  }
};
</script>

<style lang="scss">
.serviceItemBox {
  background-color: #666363;
  border-style: solid;
  border-color: #a8a8a8;
  color: #ffffff;
  border-radius: 20px;
  height: 300px;
  width: 300px;
  padding: 20px;
  margin: 20px;
  font-size: 30px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.serviceItemtext {
  padding-top: 30%;
  transform: 0.3s;
}

.serviceItemBox:hover {
  box-shadow: 2px 2px 10px black;
  border-color: #111111;
  color: #000000;
  background-color: var(--onHover-color);
}

.topLeftMessage {
  position: absolute;
  left: 0;
  top: 0;
  margin: 15px;
  font-weight: bold;
  font-size: 15px;
  background-color: red;
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
}

.tonIcon {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 600px) {
  .serviceItemBox {
    margin-left: 60px;
  }
}
</style>
