import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PageNotFound from "../views/WebStatus/PageNotFound.vue";
import Tutorial from "../views/Tutorial.vue";
import Services from "../views/Services/Services.vue";
import SignIn from "../views/Authentication/SignIn.vue";
import NProgress from "nprogress";
import Ton from "../views/Ton/Ton.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: Tutorial
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/auth/signin",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/ton",
    name: "Ton",
    component: Ton
  },
  {
    path: "*",
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
    NProgress.inc(0.2);
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
