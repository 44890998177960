<template>
  <div>
    <div class="tableRow">
      <div class="propertyText">{{ rowData.displayProperty }}</div>
      <div class="valueBox">
        <div v-if="!rowData.value">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="20"
            :md-stroke="3"
          ></md-progress-spinner>
        </div>
        <div v-else>
          <div :id="rowData.value" :class="rowData.valueClass || 'boldText'">
            {{ rowData.value }}
          </div>
        </div>
      </div>
      <md-tooltip md-direction="right" v-if="rowData.value"
        >Click to copy</md-tooltip
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
.tableRow {
  display: inline-flex;
  font-size: 20px;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  cursor: pointer;
  gap: 10px;

  .propertyText {
    flex: 2;
    text-align: center;
    font-weight: bold;
    vertical-align: baseline;
    padding-top: 10px;
  }
  .valueBox {
    background-color: #272727;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    flex: 5;
    position: relative;
    transition: 0.2s;
    flex-wrap: wrap;
    min-width: 300px;
    word-wrap: break-word;

    .boldText {
      font-weight: bold;
    }

    .grey {
      color: #aaaaaa;
    }

    .red {
      color: #fc9696;
    }

    .italic {
      font-style: italic;
    }

    &:hover {
      background-color: #494949;
    }
  }
}
</style>
