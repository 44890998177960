<template>
  <div class="SignInView">
    <md-card>
      <md-card-header>
        <md-card-media>
          <img src="../../assets/images/customer.svg" alt="Account" />
        </md-card-media>
        <md-card-header-text>
          <div class="md-title">.Dev Account</div>
          <div class="md-subhead">Sign In</div>
        </md-card-header-text>
      </md-card-header>
      <form novalidate @submit.stop.prevent="showSnackbar = true">
        <md-card-content>
          <md-field>
            <label>Email (example@sirateek.dev)</label>
            <md-input v-model="type"></md-input>
          </md-field>
          <md-field>
            <label>Password</label>
            <md-input type="password" v-model="type"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions>
          <md-button
            class="md-dense md-raised md-primary"
            @click.native="emailLogin"
            >Sign In</md-button
          >
        </md-card-actions>
      </form>

      <md-divider></md-divider>
      <md-card-actions>
        <div class="md-subhead" style="margin-right: 10px">or</div>
        <md-button
          class="md-icon-button md-raised md-accent"
          @click.native="socialGoogleLogin"
        >
          <i class="fab fa-google"></i>
        </md-button>
        <md-button
          class="md-icon-button md-raised"
          style="background-color: #00c300"
          @click.native="socialLineLogin"
        >
          <img src="../../assets/images/line.png" alt="Line Logo" />
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import firebase from "firebase";
import liff from "@line/liff";

export default {
  name: "SignIn",
  components: {},
  data: () => {
    return {
      snackBarMessage: "",
      showSnackbar: false
    };
  },
  created() {
    liff.init({
      liffId: "1655374134-Qo8JB3mE"
    });
  },
  methods: {
    emailLogin: () => {
      this.$snackbar.showSnack({
        text: "Successfully Saved!",
        color: "success",
        timeout: 3500,
        isShow: true
      });
      // this.$set(
      //   this.snackBarMessage,
      //   "snackBarMessage",
      //   "Sorry. This SignIn Method is under development."
      // );
      // this.$set(this.snackBarMessage, "showSnackbar", true);

      console.log("Hello!");
    },
    socialGoogleLogin: () => {
      const provide = new firebase.auth.GoogleAuthProvider().addScope("email");
      firebase
        .auth()
        .signInWithPopup(provide)
        .then(result => {
          // create user in db
          var obj = {
            google_id: result.additionalUserInfo.profile.id,
            fullname: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
            profile_image: result.additionalUserInfo.profile.picture,
            user_type_id: 1
          };
          console.log(obj);
        })
        .catch(err => {
          alert("Oops. " + err.message);
        });
    },
    socialLineLogin: () => {
      return liff.ready.then(() => {
        console.log(liff.id);
        /* liff.login({
          redirectUri: "https://beta-sirateek-dev.web.app/"
        }); */
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.md-card {
  margin-top: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 20px;
}
</style>

<style>
.md-card-actions {
  margin-right: 10px;
  margin-bottom: 10px;
}
.md-card-header-text {
  margin-left: 30px;
}
.md-card-header {
  margin: 20px;
}
.SignInView {
  margin-left: 50px;
  margin-right: 50px;
}
</style>
