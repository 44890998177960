<template>
  <div class="home">
    <div class="devInfoBox">
      <div>
        <img src="../assets/images/myPhoto.png" class="devImage" />
      </div>
      <div class="devInfoSection">
        <div class="title">Siratee Kittiwitchaowakul</div>
        <div class="subtitle">
          <b class="google">
            <b class="blue">G</b>
            <b class="red">o</b>
            <b class="orange">o</b>
            <b class="blue">g</b>
            <b class="green">l</b>
            <b class="red">e</b>
          </b>
          Developer Student Club Lead @
          <b class="ku">KU</b>
        </div>
        <div class="subtitle">
          Junior Flutter Developer @
          <img
            src="../assets/images/qchang-logo.png"
            width="12%"
            height="12%"
          />
        </div>
        <div class="subtitle">
          Software Engineering Student @ <b class="ku">KU</b>
        </div>
        <br />
        <div class="subtitle">
          Bangkok, Thaniland
        </div>
        <div class="subtitle">
          <a href="mailto:me@sirateek.dev">
            me@sirateek.dev
          </a>
        </div>
        <div class="contact">
          <a class="github" href="https://github.com/sirateek" target="_blank">
            <i class="fab fa-github"></i
          ></a>
          <a class="medium" href="https://medium.com/@sirateek" target="_blank"
            ><i class="fab fa-medium"></i
          ></a>
          <a
            class="facebook"
            href="https://facebook.com/sirateek"
            target="_blank"
            ><i class="fab fa-facebook"></i
          ></a>
          <a
            class="twitter"
            href="https://twitter.com/@sirateek28"
            target="_blank"
            ><i class="fab fa-twitter"></i
          ></a>
          <a class="line" href="https://line.me/ti/p/LX-vobYg6k" target="_blank"
            ><i class="fab fa-line"></i
          ></a>
        </div>
      </div>
    </div>
    <md-divider></md-divider>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {}
};
</script>

<style lang="scss" scoped>
.home {
  margin-left: 30px;

  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
.devInfoBox {
  display: grid;
  margin: 10%;
  grid-template-columns: 30% 70%;
  flex-wrap: wrap;
  grid-gap: 50px;

  @media only screen and (max-width: 600px) {
    margin-top: 15%;
    margin: 10%;
    grid-template-columns: 100%;
  }

  .devImage {
    width: auto;
    height: auto;
    border-radius: 20px;
    min-width: 100px;
  }

  .devInfoSection {
    text-align: left;

    position: relative;
    .google {
      .blue {
        color: #4285f4;
      }
      .red {
        color: #db4437;
      }

      .orange {
        color: #f4b400;
      }

      .green {
        color: #0f9d58;
      }
    }

    .ku {
      color: #228b22;
    }

    .title {
      font-weight: bold;
      font-size: 50px;
    }
    .subtitle {
      padding-top: 15px;
      font-weight: bold;
      font-size: 26px;
    }

    .contact {
      color: black;
      transition: 0.2s ease-out;

      a {
        color: inherit;
      }
      .facebook:hover {
        color: #3b5998;
      }
      .twitter:hover {
        color: #00acee;
      }
      .line:hover {
        color: #00b900;
      }
    }

    .contact > * {
      margin: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    .devInfoBox {
      margin: 10px;
      grid-template-columns: repeat(auto-fit, 300px);
    }
  }
}
</style>
